<template>
  	<div>
    	<main class="container-fluid mt-0">
			<div class="text-center d-none d-md-block d-lg-block" style="position: absolute; top: 3vh; left: 44%; z-index: 1;">
				<router-link to="/"><img src="@/assets/images/logo.png" width="150px" /></router-link>
			</div>
			<div class="row mx-md-4 my-4 d-flex align-items-center">
				<div class="col-md-8 text-center d-none d-md-block d-lg-block">
					<img src="@/assets/images/img-login.png" width="70%" />
				</div>
				<div class="col-md-12 d-md-none d-lg-none text-center pb-3">
					<router-link to="/"><img src="@/assets/images/logo.png" width="150px" /></router-link>
				</div>
				<div class="col-md-4" v-if="req_id">
					<div class="card shadow border-success round">
						<div class="card-body p-4">
							<div class="text-center py-2">
								<i class="far fa-envelope-open fa-6x text-info"></i>
							</div>
							<form class="px-3 py-2" @submit.prevent="set_newPass">
								<h4 class="text-center">Lupa Kata Sandi</h4>
								<hr class="my-3 w-75" />
								<label for="inlineFormInputGroupKataSandiForgot" class="text-muted mb-1">Kata Sandi Baru</label>
								<div class="input-group mb-2 mr-sm-2">
									<div class="input-group-prepend">
										<div class="input-group-text bg-white"><i class="fa fa-key text-info"></i></div>
									</div>
									<input type="password" v-model="password" name="password" class="form-control" id="inlineFormInputGroupKataSandiForgot" placeholder="Kata Sandi" required />
								</div>
								<div class="text-center mt-4">
									<button type="submit" class="btn btn-info w-100">Simpan</button>
									<hr class="my-3 w-75" />
									<p class="text-muted mb-0">Belum punya akun? <router-link to="/register" class="text-success text-decoration-none">Daftar</router-link></p>
									<hr class="w-25 my-1" />
									<p class="text-muted mb-0">Masuk melalui akun lain? <router-link to="/login" class="text-primary text-decoration-none">Masuk</router-link></p>
								</div>
							</form>
						</div>
					</div>
				</div>
				<div class="col-md-4" v-else>
					<div class="card shadow border-success round">
						<div class="card-body p-4">
							<div class="text-center py-2">
								<i class="far fa-question-circle fa-6x text-info"></i>
							</div>
							<form class="px-3 py-2" @submit.prevent="forgotPass">
								<h4 class="text-center">Lupa Kata Sandi</h4>
								<hr class="my-3 w-75" />
								<label for="inlineFormInputGroupEmailForgot" class="text-muted mb-1">Pulihkan melalui Email Anda</label>
								<div class="input-group mb-2 mr-sm-2">
									<div class="input-group-prepend">
										<div class="input-group-text bg-white"><i class="fa fa-envelope text-info"></i></div>
									</div>
									<input type="email" class="form-control" v-model="email" id="inlineFormInputGroupEmailForgot" placeholder="Email" required />
								</div>
								<div class="text-center mt-4">
									<button type="submit" class="btn btn-info w-100">Kirim</button>
									<hr class="my-3 w-75" />
									<p class="text-muted mb-0">Belum punya akun? <router-link to="/register" class="text-success text-decoration-none">Daftar</router-link></p>
									<hr class="w-25 my-1" />
									<p class="text-muted mb-0">Masuk melalui akun lain? <router-link to="/login" class="text-primary text-decoration-none">Masuk</router-link></p>
								</div>
							</form>
						</div>
					</div>
					<div class="text-center py-3"><a href="#" @click="$router.go(-1)" class="text-secondary text-decoration-none">Kembali</a></div>
				</div>
			</div>
		</main>
  	</div>
</template>

<script>

export default {
	data () {
 		return {
			email: '',
			password: '',
 		}
	},
    computed: {
        req_id: function () {
			return this.$route.query.req
        },
	},
	methods: {
		forgotPass: function () {
			let formData = new FormData();
			formData.append('email', this.email)

			window.axios.post('/forgot', formData, {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                }
            )
 			.then((response) => {
				this.email = ''
				if(response.data.success) {
					this.$swal({
						title: 'Berhasil', 
						html: '<p>Data pergantian Kata Sandi telah kami kirimkan, Silahkan periksa email Anda!</p>',
						icon: 'success',
						timer: 8000,
						showConfirmButton: false,
					});
					this.$router.push('/login')
				}
				else {
					this.$swal({
						title: 'Oops!', 
						html: `
								<p class="mb-1">Pengguna belum terdaftar</p>
								<p>Klik <a href="/register" class="text-info text-decoration-none">di sini</a> untuk mendaftar!</p>
							`,
						icon: 'error',
						timer: 7000,
						showConfirmButton: false,
					});
				}
 			})
 			.catch((error) => {
				this.email = ''
				this.$swal({
					title: 'Oops!', 
					html: `
							<p class="mb-1">Pengguna belum terdaftar</p>
							<p>Klik <a href="/register" class="text-info text-decoration-none">di sini</a> untuk mendaftar!</p>
						`,
					icon: 'error',
					timer: 7000,
					showConfirmButton: false,
				});
			})
      	},
		set_newPass: function () {
			let formData = new FormData();
			formData.append('new_password', this.password)

			window.axios.post('/forgot/new-password?req='+ this.req_id, formData, {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                }
            )
 			.then((response) => {
				this.password = ''
				if(response.data.success) {
					this.$swal({
						title: 'Berhasil', 
						html: '<p>Kata Sandi berhasil diperbarui!</p>',
						icon: 'success',
						timer: 4000,
						showConfirmButton: false,
					});
					this.$router.push('/login')
				}
				else {
					this.$swal({
						title: 'Oops!', 
						html: '<p>Terjadi Kesalahan.</p>',
						timer: 4000,
						showConfirmButton: false,
					});
				}
 			})
 			.catch((error) => {
				this.password = ''
				this.$swal({
					title: 'Oops!', 
					html: '<p>Terjadi Kesalahan.</p>',
					icon: 'error',
					timer: 4000,
					showConfirmButton: false,
				});
			})
      	}
	},
	created() {
		$(document).scrollTop(0)
	}  
}
</script>